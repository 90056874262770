<template>
  <!-- 资产业务分布情况统计 -->
  <div class="mapContent">
    <title-name-slot title="资产业务分布情况统计">
      <div slot="operation">
        <!-- 切换tab -->
        <ul class="tabList">
          <li
            :class="mapIndex === index ? 'active' : ''"
            v-for="(item, index) in mapTab"
            :key="index"
            @click="areaStatistics(index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </title-name-slot>
    <!-- 地图数据 -->
    <div class="statistics1 chartsContent" id="map"></div>
    <!-- 分布统计总数据 -->
    <div class="mapInfo">
      <div class="item">
        <i
          class="iconfont iconzongbishu"
          style="color: #ff9f75; font-size: 18px"
        ></i>
        <span class="name">总笔数: </span>
        <span class="num">{{ areaInfo.penNumber }}</span>
      </div>
      <div class="item">
        <i
          class="iconfont iconjine"
          style="color: #faa9b4; font-size: 20px"
        ></i>
        <span class="name">总金额(万元): </span>
        <span class="num" v-if="areaInfo.replyQuota">{{
          (areaInfo.replyQuota/10000).toLocaleString("en-US")
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import titleNameSlot from './title-name-slot.vue'
import geoCoordMap from '../utils/coord.js'
import { statisticsApi } from '@/api/homeApi'
export default {
  name: 'serviceDistributionStatistics',
  components: { titleNameSlot },
  data () {
    return {
      myChart1: '',
      first: true,
      mapTab: ['全部', '授信审批阶段', '签约阶段', '贷后阶段', '已还款'],
      mapIndex: 0,
      areaInfo: {
        penNumber: 0,
        replyQuota: 0
      }
    }
  },
  computed: {
    api () {
      return statisticsApi
    }
  },
  mounted () {
    this.areaStatistics(0)
  },
  activated () {
    this.areaStatistics(0)
  },
  methods: {
    // 资产业务分布情况统计
    areaStatistics (index) {
      this.mapIndex = index
      if (index === 0) {
        index = ''
      }
      this.api.areaStatistics({ status: index ? index.toString() : '' }).then((res) => {
        if (res.success) {
          this.areaInfo = {
            penNumber: 0,
            replyQuota: 0
          }
          if (res.data.value) {
            this.areaData = res.data.value
            this.areaInfo = {
              penNumber: res.data.penNumberSum,
              replyQuota: res.data.replyQuotaSum
            }
            this.setEcharts1()
          } else {
            this.error('资产业务分布情况统计暂无数据')
          }
        }
      })
    },
    // 获取坐标位置
    getCoord (name) {
      for (const k in geoCoordMap) {
        if (name === k) {
          return geoCoordMap[k]
        }
      }
    },
    // 获取是否存在当前区域
    getRegionsName (name) {
      let state = false
      this.regions.forEach((ele) => {
        if (ele.name === name && ele.name) {
          state = true
        }
      })
      return state
    },
    // 资产业务分布情况统计
    setEcharts1 () {
      this.regions = []
      const dataList = []
      this.loanAmountCountTotal = 0
      this.customersNumberTotal = 0
      this.strokeCountTotal = 0
      if (!this.myChart1) {
        this.myChart1 = this.$echarts.init(document.getElementById('map'))
      }
      this.areaData.forEach((ele) => {
        const obj = {
          name: ele.areaGroup,
          itemStyle: {
            normal: {
              // borderColor: '#ff9b84',
              borderWidth: 1
            }
          }
        }
        const objs = {
          name: ele.areaGroup,
          coord: ele.areaGroup ? this.getCoord(ele.areaGroup) ? this.getCoord(ele.areaGroup) : [] : [], // 坐标数据
          ...ele
        }
        this.regions.push(obj) // 对地图省份样式进行修改数据
        dataList.push(objs) // 地图上图标显示数据
      })
      this.myChart1.setOption({
        backgroundColor: '',
        title: {},
        animation: false,
        tooltip: {
          show: true,
          trigger: 'item',
          transitionDuration: 0,
          backgroundColor: '',
          formatter: (param) => {
            // 自定义鼠标放到省份显示弹窗
            if (this.getRegionsName(param.name)) {
              const html =
                param.name +
                ' <br>' +
                '<div style="display:flex;width:175px;">' +
                '<span style="flex: 1">笔数</span>' +
                '<span>' +
                param.data?.penNumber +
                '</span>' +
                '</div>' +
                '<div style="display:flex;width:175px;">' +
                '<span style="flex: 1">金额(万元)</span>' +
                '<span>' +
                (param.data?.replyQuota
                  ? parseFloat((param.data?.replyQuota) / 10000).toLocaleString('en-US', {
                    minimumFractionDigits: 2
                  })
                  : 0) +
                '</span>' +
                '</div>'
              return (
                `<div style="padding:14px 25px;color:#2D363E;background:url(${require('../../../assets/home/bj.png')}) no-repeat;background-size:224px 90px;">` +
                html +
                '</div>'
              )
            } else {
              return ''
            }
          }
        },
        geo: {
          map: 'china',
          type: 'map',
          label: {
            emphasis: {
              show: true
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#a4bdf7',
              borderColor: '#FFFDF9'
            },
            emphasis: {
              areaColor: '#fe9160'
            }
          },
          roam: false,
          zoom: 1.1,
          regions: this.regions
        },
        series: [
          {
            name: '地图',
            type: 'map',
            map: 'china',
            markPoint: {
              symbol: 'image://' + require('../../../assets/home/dot.png'),
              symbolSize: [18, 18],
              label: {
                show: false
              },
              silent: true,
              data: dataList
            },
            data: dataList,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: true
              }
            },
            roam: true,
            itemStyle: {
              normal: {
                areaColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(0,0,0,0)'
              },
              emphasis: {
                areaColor: '#4A7CF0'
              }
            },
            zoom: 1.1
          }
        ]
      })
      if (this.first) {
        // 地图缩放移动处理geo地图和series地图同步移动
        this.myChart1.on('georoam', (params) => {
          var option = this.myChart1.getOption()
          if (params.zoom != null && params.zoom !== undefined) {
            option.geo[0].zoom = option.series[0].zoom
            option.geo[0].center = option.series[0].center
          } else {
            option.geo[0].center = option.series[0].center
          }
          this.myChart1.setOption(option)
        })
        this.first = false
      }
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
