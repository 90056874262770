<template>
  <!-- 融资产品情况统计模块 -->
  <div class="productSituation">
    <title-name-slot title="融资产品情况统计">
      <!-- 切换tab -->
      <div slot="operation">
        <ul class="tabList">
          <li
            :class="productIndex === index ? 'active' : ''"
            v-for="(item, index) in productTab"
            :key="index"
            @click="productIndexClick(item, index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </title-name-slot>
    <!-- 饼图数据 -->
    <div class="statistics2 chartsContent" id="echarts2"></div>
  </div>
</template>
<script>
import titleNameSlot from './title-name-slot.vue'
import { statisticsApi } from '@/api/homeApi'
export default {
  name: 'productStatistics',
  components: { titleNameSlot },
  data () {
    return {
      myChart2: '',
      productTab: ['笔数占比', '金额占比'],
      productIndex: 0,
      productType: '笔数占比',
      productData: []
    }
  },
  computed: {
    api () {
      return statisticsApi
    }
  },
  mounted () {
    this.finaaceProductStatistics()
  },
  activated () {
    this.finaaceProductStatistics()
  },
  methods: {
    // 融资产品情况统计
    finaaceProductStatistics () {
      this.api.finaaceProductStatistics().then((res) => {
        if (res.success) {
          this.productData = res.data
          this.setEcharts2()
        }
      })
    },
    // 切换分类
    productIndexClick (type, index) {
      this.productType = type
      this.productIndex = index
      this.setEcharts2()
    },
    // 融资产品情况统计
    setEcharts2 () {
      this.myChart2 = this.$echarts.init(document.getElementById('echarts2'))
      // 数据处理
      this.productData.map((item) => {
        if (this.productType === '笔数占比') {
          item.name = item.finaaceProductName
          item.value = item.penNumber
        } else {
          item.name = item.finaaceProductName
          item.value = item.replyQuota
          if (item.replyQuota.toFixed().length > 8) {
            this.$set(item, 'showValue', (parseFloat(item.replyQuota) / 100000000).toFixed(2))
            this.$set(item, 'unit', '亿元')
          } else if (item.replyQuota.toFixed().length > 4) {
            this.$set(item, 'showValue', (parseFloat(item.replyQuota) / 10000).toFixed(2))
            this.$set(item, 'unit', '万元')
          } else {
            this.$set(item, 'showValue', item.replyQuota)
            this.$set(item, 'unit', '元')
          }
        }

        return item
      })
      if (this.productData.length === 0) {
        this.productData = [{ name: '暂无数据', value: 0 }]
      }

      this.myChart2.setOption({
        tooltip: {
          trigger: 'item',
          formatter: (param) => {
            console.log(param, 'param')
            if (this.productType === '笔数占比') {
              const html = param.name + ':' + param.value + '笔'
              return html
            } else {
              const html = param.name + ':' + param.data.showValue + param.data.unit
              return html
            }
          }
        },
        legend: {
          icon: 'circle',
          fontSize: '12px',
          orient: 'vertical',
          bottom: 'bottom'
        },
        // 自定义颜色
        color: [
          '#D1E6A6',
          '#FFDE95',
          '#CCABDB',
          '#85E4CD',
          '#FA887A',
          '#ff5555',
          '#ff8c00',
          '#ffd700',
          '#90ee90',
          '#00ced1',
          '#1e90ff',
          '#c71585'
        ],
        series: [
          {
            name: '',
            type: 'pie',
            radius: '40%',
            data: this.productData,
            label: {
              // formatter: `{b}: {@2012} ${this.productType === '笔数占比' ? '笔' : '万元'}`
              formatter: (param) => {
                if (this.productType === '笔数占比') {
                  const html = param.name + ':' + param.value + '笔'
                  return html
                } else {
                  const html = param.name + ':' + param.data.showValue + param.data.unit
                  return html
                }
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
