<template>
  <!-- 融资风险情况统计 -->
  <div class="riskStatistics">
    <title-name-slot title="融资风险情况统计">
      <!-- 时间段选择 -->
      <div slot="operation">
        <el-date-picker
          type="daterange"
          v-model="date"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="changeDate"
          placeholder="请选择"
        ></el-date-picker>
      </div>
    </title-name-slot>
    <!-- 柱状图数据 -->
    <div class="statistics3 chartsContent" id="echarts3"></div>
  </div>
</template>
<script>
import titleNameSlot from './title-name-slot.vue'
import { statisticsApi } from '@/api/homeApi'
export default {
  name: 'riskStatistics',
  components: { titleNameSlot },
  data () {
    return {
      date: [],
      myChart3: '',
      riskData: []
    }
  },
  computed: {
    api () {
      return statisticsApi
    }
  },
  mounted () {
    this.riskStatistics()
  },
  activated () {
    this.riskStatistics()
  },
  methods: {
    // 融资风险情况统计
    riskStatistics (date) {
      let params = {}
      if (date && date.length > 0) {
        params = {
          startDate: date[0],
          endDate: date[1]
        }
      }
      this.api.riskStatistics(params).then((res) => {
        if (res.success) {
          this.riskData = res.data
          this.setEcharts3()
        }
      })
    },
    changeDate (date) {
      this.riskStatistics(date)
    },
    // 融资风险情况统计
    setEcharts3 () {
      // 默认设置颜色
      const color = [
        '#C0FFF0',
        '#FF998E',
        '#5ED0F5',
        '#2862E7',
        '#FFDE95',
        '#ff5555',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585'
      ]
      const namaData =
        this.riskData.map((item) => {
          return item.riskTypeName
        })
      const dataList =
        this.riskData.map((item, index) => {
          return {
            value: item.count,
            itemStyle: {
              color: color[index] ? color[index] : ''
            }
          }
        })
      if (this.riskData.length === 0) {
        namaData.push('暂无数据')
        dataList.push({
          value: 0,
          itemStyle: {
            color: color[0] ? color[0] : ''
          }
        })
      }
      this.myChart3 = this.$echarts.init(document.getElementById('echarts3'))
      this.myChart3.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0
            },
            type: 'category',
            boundaryGap: true,
            data: namaData,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            minInterval: 1,
            name: '笔数',
            // max: 30,
            min: 0
          }
        ],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            name: '笔数',
            type: 'bar',
            barWidth: 15,
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: function (v) {
                  var val = v.data.value
                  return val + '笔'
                },
                color: '#FFFFFF'
              }
            },
            data: dataList
          }
        ]
      })
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
