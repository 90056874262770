<template>
  <!-- 供应商融资情况排名 -->
  <div class="ranking">
    <title-name-slot title="供应商融资情况排名"></title-name-slot>
    <div class="mainContent">
      <div class="dataList">
        <ul class="titleData">
          <li class="column1">排名</li>
          <li class="column2">供应商名称</li>
          <li class="column3">金额 ( 万元 )</li>
        </ul>
        <div class="dataMain">
          <!-- 供应商排名数据 -->
          <template v-if="gysData.length > 0">
            <ul
              class="titleData dataItem"
              v-for="(item, index) in gysData"
              :key="index"
            >
              <li class="column1" v-if="index === 0">
                <img src="../../../assets/home/1.png" />
              </li>
              <li class="column1" v-else-if="index === 1">
                <img src="../../../assets/home/2.png" />
              </li>
              <li class="column1" v-else-if="index === 2">
                <img src="../../../assets/home/3.png" />
              </li>
              <li class="column1" v-else>{{ index + 1 }}</li>
              <el-tooltip
                v-if="item.gysCompanyName"
                effect="dark"
                popper-class="tooltipClass"
                :content="item.gysCompanyName"
                :enterable="false"
                placement="top-start"
                :disabled="disabledarrs[index]"
              >
                <li class="column2" ref="valueboxs">{{ item.gysCompanyName }}</li>
              </el-tooltip>
              <li class="column2" v-else>{{ item.gysCompanyName }}</li>
                <el-tooltip  effect="dark" :content="(item.replyQuota/10000).toLocaleString('en-US')" placement="top-end" :disabled="disabledarrQuota[index]"  :enterable="false">
                <li class="column3" v-if="item.replyQuota != undefined" ref="valueQuota">
                {{ parseFloat((item.replyQuota/10000)).toLocaleString("en-US") }}
                </li>
                </el-tooltip>
            </ul>
          </template>
          <div v-else class="noData">暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleNameSlot from './title-name-slot.vue'
import { statisticsApi } from '@/api/homeApi'
export default {
  name: 'supplierFinancingRanking',
  components: { titleNameSlot },
  props: {
    countIndex: Number
  },
  data () {
    return {
      gysData: [],
      counts: 0,
      disabledarrs: [],
      disabledarrQuota: []
    }
  },
  computed: {
    api () {
      return statisticsApi
    }
  },
  watch: {
    counts: {
      handler (val) {
        this.$nextTick(() => {
          this.getwidths()
          this.getwidthQuota()
        })
      },
      deep: false
    },
    countIndex: {
      handler (val) {
        this.counts++
      },
      deep: false
    }
  },
  mounted () {
    this.gysStatistics()
  },
  activated () {
    this.gysStatistics()
  },
  methods: {
    // 获取供应商名称内容宽度
    getwidths () {
      const dom = this.$refs.valueboxs
      if (dom) {
        dom.forEach((item, index) => {
          this.$set(this.disabledarrs, index, item.offsetWidth >= item.scrollWidth)
        })
      }
    },

    // 获取金额内容宽度
    getwidthQuota () {
      const dom = this.$refs.valueQuota
      if (dom) {
        dom.forEach((item, index) => {
          this.$set(this.disabledarrQuota, index, item.offsetWidth >= item.scrollWidth)
        })
      }
    },
    // 供应商融资情况排名
    gysStatistics () {
      this.api.gysStatistics().then((res) => {
        if (res.success) {
          this.gysData = res.data.slice(0, 10)
          this.counts++
        }
      })
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
