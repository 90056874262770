<template>
  <!-- 资金方统计模块 -->
  <div class="left_top">
    <title-name-slot title="资金方统计"></title-name-slot>
    <div class="topMain">
      <div class="top_content">
        <!-- 资金方数据 -->
        <ul v-if="sideList.length > 0">
          <li v-for="(item, index) in sideList" :key="index">
            <div class="contentTitle">
              <img class="icon" :src="item.imgUrl" />
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.capitalSideName"
                  placement="top"
                  :disabled="disabledarr[index]"
                >
                  <span  ref="valuebox">{{ item.capitalSideName }}</span>  </el-tooltip
                >
              <img
                class="state"
                v-if="item.replyStatus"
                :src="item.replyStatus !== '0' ? reply : inReply"
              />
            </div>
            <div class="numContent">
              <p>
                <span>总额度(万元)</span
                >
                  <el-tooltip    effect="dark"  :content="(item.replyQuota/10000).toString()" placement="top" :disabled="disabledarrMoneyAll[index]">
                  <span class="num" ref="moneyAll">{{ (item.replyQuota/10000).toLocaleString("en-US")  }}</span>
                </el-tooltip>

              </p>
              <p>
                <span>剩余额度(万元)</span
                >
                  <el-tooltip   effect="dark"  :content="item.surplusQuota.toString()" placement="top" :disabled="disabledarrMoneySurplus[index]">
                    <span class="num"  ref="moneySurplus">{{ (parseFloat(item.surplusQuota) === 0 || item.replyStatus === '0')  ? '-':(item.surplusQuota/10000).toLocaleString("en-US") }}</span>
                </el-tooltip>
              </p>
              <p>
                <span>客户数</span
                >
                <!-- <el-tooltip  :content="item.clientNumber" placement="top"> -->
                    <span class="num">{{ (parseFloat(item.clientNumber) === 0  || item.replyStatus === '0') ? '-':item.clientNumber }}</span>
                <!-- </el-tooltip> -->
              </p>
              <p>
                <span>笔数</span
                >
                  <!-- <el-tooltip  :content="item.penNumber" placement="top"> -->
                    <span class="num">{{ (parseFloat(item.penNumber) === 0 || item.replyStatus === '0') ? '-':item.penNumber }}</span>
                <!-- </el-tooltip> -->
              </p>
            </div>
          </li>
        </ul>
        <div v-else class="noData">暂无数据</div>
        <div style="clear: both"></div>
      </div>
    </div>
    <!-- 资金方换页 -->
    <div class="btn_footer">
      <img
        @click="leftBtn"
        v-if="this.activeIndex > 1"
        class="leftBtn"
        src="../../../assets/home/new-right.png"
        alt=""
      />
      <img
        @click="rightBtn"
        v-if="this.total > 4 && this.activeIndex < this.sideIndex"
        class="rightBtn"
        src="../../../assets/home/new-right.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { fileAPi } from '@/api/fileApi'
import reply from '../../../assets/home/reply.png'
import inReply from '../../../assets/home/inReply.png'
import mRImg from '@/assets/home/bank.png'
import titleNameSlot from './title-name-slot.vue'
import { statisticsApi } from '@/api/homeApi'
export default {
  name: 'fundSideStatistics',
  components: { titleNameSlot },
  props: {
    countIndex: Number
  },
  data () {
    return {
      sideIndex: 0,
      sideList: [],
      activeIndex: 1,
      queryParms: {
        pageIndex: 1,
        pageSize: 4,
        replyStatusList: ['0', '1']
      },
      count: 0,
      total: '',
      disabledarr: [],
      disabledarrMoneyAll: [],
      disabledarrMoneySurplus: [],
      mRImg: mRImg
    }
  },
  computed: {
    api () {
      return statisticsApi
    },
    reply () {
      return reply
    },
    inReply () {
      return inReply
    }
  },
  watch: {
    count: {
      handler (val) {
        this.$nextTick(() => {
          this.getwidth()
          this.getwidthMoneyAll()
          this.getwidthMoneySurplus()
        })
      },
      deep: false
    },
    countIndex: {
      handler (val) {
        this.count++
      },
      deep: false
    }
  },
  mounted () {
    this.sideStatistics()
  },
  activated () {
    this.sideStatistics()
  },
  methods: {
    // 获取内容宽度
    getwidth () {
      this.dom = this.$refs.valuebox
      if (this.dom) {
        this.dom.forEach((item, index) => {
          this.$set(this.disabledarr, index, item.offsetWidth >= item.scrollWidth)
        })
      }
    },
    // 获取银行总金额内容宽度
    getwidthMoneyAll () {
      const dom = this.$refs.moneyAll
      if (dom) {
        dom.forEach((item, index) => {
          this.$set(this.disabledarrMoneyAll, index, item.offsetWidth >= item.scrollWidth)
        })
      }
    },
    // 获取银行剩余金额内容宽度
    getwidthMoneySurplus () {
      const dom = this.$refs.moneySurplus
      if (dom) {
        dom.forEach((item, index) => {
          this.$set(this.disabledarrMoneySurplus, index, item.offsetWidth >= item.scrollWidth)
        })
      }
    },
    // 左移
    leftBtn () {
      this.activeIndex--
      this.queryParms.pageIndex = this.activeIndex
      this.sideStatistics()
    },
    // 右移
    rightBtn () {
      this.activeIndex++
      this.queryParms.pageIndex = this.activeIndex
      this.sideStatistics()
    },
    // 资金方统计
    sideStatistics () {
      this.api.sideStatistics(this.queryParms).then((res) => {
        if (res.success) {
          for (let i = 0; i < res.data.records.length; i++) {
            const ele = res.data.records[i]
            if (ele.imageUrl) {
              fileAPi.downloadKeyId(ele.imageUrl).then(res => {
                if (res.success) {
                  if (res.data) {
                    this.$set(ele, 'imgUrl', res.data.uri)
                  } else {
                    this.$set(ele, 'imgUrl', this.mRImg)
                  }
                }
              })
            } else {
              this.$set(ele, 'imgUrl', this.mRImg)
            }
          }
          this.total = res.data.total
          this.sideIndex = parseInt(this.total / 4) + (this.total % 4 > 0 ? 1 : 0)
          this.sideList = res.data.records
          this.count++
        }
      })
    }
  }
}
</script>
<style lang="scss" src="../index.scss" scoped></style>
