<!-- 首页 -->
<template>
  <div class="dashboard">
    <div class="left">
      <!-- 资金方统计 -->
      <fund-side-statistics :countIndex='countIndex'></fund-side-statistics>
      <!-- 放款金额占比 -->
      <loan-money-proportion ref="loadData"></loan-money-proportion>
      <!-- 风控分配在处理数量 -->
      <!-- <risk-control-allocation ref="riskControlData"></risk-control-allocation> -->
    </div>
    <div class="right">
      <!-- 资产业务分布情况统计 -->
      <service-distribution-statistics ref="serviceData"></service-distribution-statistics>
      <!-- 融资产品情况统计 -->
      <product-statistics ref="productData"></product-statistics>
      <!-- 融资风险情况统计 -->
      <risk-statistics ref="riskData"></risk-statistics>
      <!-- 供应商融资情况排名 -->
      <supplier-financing-ranking :countIndex='countIndex'></supplier-financing-ranking>
    </div>
  </div>
</template>

<script>
import FundSideStatistics from './components/fund-side-statistics.vue'
import LoanMoneyProportion from './components/loan-money-proportion.vue'
import ServiceDistributionStatistics from './components/service-distribution-statistics.vue'
import ProductStatistics from './components/product-statistics.vue'
import RiskStatistics from './components/risk-statistics.vue'
import SupplierFinancingRanking from './components/supplier-financing-ranking.vue'
// import RiskControlAllocation from './components/risk-control-allocation.vue'
export default {
  name: 'dashboard',
  components: { FundSideStatistics, LoanMoneyProportion, ServiceDistributionStatistics, ProductStatistics, RiskStatistics, SupplierFinancingRanking },
  data () {
    return {
      countIndex: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeEvent, false)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeEvent)
  },
  methods: {
    resizeEvent () {
      if (this.$refs.loadData.myChart) this.$refs.loadData.myChart.resize()
      if (this.$refs.serviceData.myChart1) this.$refs.serviceData.myChart1.resize()
      if (this.$refs.productData.myChart2) this.$refs.productData.myChart2.resize()
      if (this.$refs.riskData.myChart3) this.$refs.riskData.myChart3.resize()
      // if (this.$refs.riskControlData.myChart4) this.$refs.riskControlData.myChart4.resize()
      this.countIndex++
    }
  }
}
</script>
<style lang='scss' src='./index.scss' scoped></style>
