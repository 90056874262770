// 地图省份坐标点
const geoCoordMap = {
  新疆: [86.61, 40.79],
  西藏: [89.13, 30.66],
  黑龙江: [128.34, 47.05],
  吉林: [127.32, 42.58],
  辽宁: [123.42, 41.29],
  内蒙古: [112.17, 42.81],
  北京: [116.40, 40.40],
  宁夏: [106.27, 36.76],
  山西: [111.95, 36.65],
  河北: [115.21, 38.44],
  天津: [117.38, 39.02],
  青海: [97.07, 35.62],
  甘肃: [104.32, 35.05],
  山东: [118.71, 36.07],
  陕西: [108.54, 33.46],
  河南: [113.46, 33.25],
  安徽: [117.28, 30.86],
  江苏: [120.26, 32.54],
  上海: [121.46, 31.28],
  四川: [102.36, 28.65],
  湖北: [111.29, 30.98],
  浙江: [120.15, 29.28],
  重庆: [107.51, 29.63],
  湖南: [112.08, 26.79],
  江西: [115.89, 27.97],
  贵州: [105.81, 25.67],
  福建: [118.31, 26.07],
  云南: [100.71, 24.04],
  台湾: [121.01, 23.54],
  广西: [108.67, 24.28],
  广东: [113.98, 23.82],
  海南: [110.03, 19.33],
  澳门: [113.54, 22.19],
  香港: [114.17, 22.32]
}

export default geoCoordMap
